<template>
  <div>
    <v-btn small color="info" outlined @click="$refs.inputUploadFile.click()"
      ><img src="@/assets/common/upload.png" style="height: 24px" alt=""
    /></v-btn>
    <input
      type="file"
      ref="inputUploadFile"
      multiple
      accept="image/*"
      @change="onInputFileChange"
      class="d-none"
    />
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { compress } from "image-conversion";
import moment from "moment";

export default {
  name: "ExportDefectiveUploadImage",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    async onInputFileChange(e) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      let files = e.target.files || e.dataTransfer.files;
      let urls = [];
      for (let i = 0; i < files.length; i++) {
        const url = await this.uploadFile(files[i]);
        urls.push(url);
      }
      this.$refs.inputUploadFile.value = null;

      try {
        await httpClient.post("/goods-export-defective-save-images", {
          images: urls,
          id: this.item.id,
        });
        this.isLoading = false;
        this.$root.$emit("playSuccessAudio");
        this.$vToastify.success(
          this.$t("messages.upload_image_handover_success")
        );
        this.$emit("refreshData");
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
        this.$root.$emit("playErrorAudio");
      }
    },

    async uploadFile(file) {
      const resBlob = await compress(file, {
        quality: 0.85,
        type: "image/jpeg",
      });
      const newFile = new File(
        [resBlob],
        `${moment().unix()}_${this.generateRandomString(10)}.jpg`,
        { type: "image/jpeg" }
      );
      let fd = new FormData();
      fd.append("file", newFile);

      const { data } = await httpClient.post(`/upload-image`, fd);
      return data;
    },
  },
};
</script>

<style scoped></style>
